@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.seo-content-field {
    display: flex !important;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .field {
        margin-bottom: 40px;
        gap: 10px;
        align-items: center;
        background-color: white;
        border-radius: 0;
        height: 40px;
        line-height: 30px;
        width: 85%;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .custom-date-picker {
        width: 85%;
        border: 1px solid #d9d9d9;
        padding: 4px 11px;
        height: 40px;
        line-height: 30px;
        border-radius: 0;
        
        &:hover {
            border-color: #40a9ff;
        }
        
        &:focus {
            border-color: #40a9ff;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            outline: 0;
        }
    }

    .language-field {
        width: 85%;

        .ant-select-selection-item {
            line-height: 35px;
        }
        
        div {
            border-radius: 0;
            height: 40px !important;
        }
    }

    .category-field {
        width: 45%;

        .ant-select-selection-item {
            line-height: 35px;
        }
        
        div {
            border-radius: 0;
            height: 40px !important;
        }
    }
}

.label {
    text-align: left !important;
    padding: 10px;
    max-height: 20px;
    width: 15%;
}


.up {
    margin-top: -8px;
}

.quill {

    margin-top: 20px;

    .ql-editor {
        min-height: 400px !important;
        height: 650px;
        font-size: 19.2px;
        background-color: white !important;
        overflow: scroll;
        font-family: Nantes, "Times New Roman", Times, serif;
        color: rgb(33, 37, 41);
        font-style: normal;

        a {
            color: #06c !important;
        }

        p {
            line-height: 28.8px;
            margin-bottom: 16px;
            margin-top: 0;
            font-size: 19.2px;
            font-weight: 400;
        }

        h1 {
            font-size: 42.24px;
            font-weight: 700;
            line-height: 50.68px;
            margin: 20px 0px
        }

        h2 {
            font-weight: 700;
            line-height: 57.6px;
            font-size: 28.8px;
            margin-bottom: 8px;
        }

        h3 {
            font-weight: 700;
            line-height: 46.08px;
            font-size: 23.04px;
            margin: 16px 0;
        }

        h4 {
            font-weight: 700;
            line-height: 72px;
            font-size: 24px;
            margin-bottom: 8px;
        }

        ol, ul, dl {
            margin-top: 0;
            margin-bottom: 16px;
        }

        ol, ul {
            padding-left: 32px;
        }

        img {
            margin-top: 16px;
        }
    }

}

.alt-editor {
    width: 400px;
    height: 50px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 10px;
    display: none;
    position: fixed;

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        line-height: 45px;
        padding: 0 10px;

        input {
            height: 20px;
            font-size: 14px;
        }

        button {
            height: 25px;
            border: 1px solid black;
            background-color: rgb(152, 243, 243);
            font-size: 14px;
        }
    }
}

.language-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  
  .language-tab {
    padding: 0.5rem 1rem;
    margin: 0.2rem;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #f9f9f9;
  }
  
  .language-tab.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .language-tab:hover {
    background-color: #0056b3;
    color: white;
  }
  